<script setup lang="ts">
import type { StateModal } from '~/types/types'

const {
  addFavorite,
  fetched,
  fetchWishlists,
  setLastWishlistTokenActive,
  wishlists,
} = useMultipleWishlist()
const { setSummaryOpen } = useMultipleWishlistSummary()
const localePath = useLocalePath()
const { t } = useI18n()
const { isMobile } = useBreakpoint()
const { trackEvent } = useTracking()
const { createNotification } = useNotifications()

const { authenticated } = useAuth()
const stateModal = useState<StateModal>('state-modal')

defineOptions({
  inheritAttrs: false,
})
const props = withDefaults(
  defineProps<{
    houseId: number | null
    houseName: string
    iconic?: boolean
    onlineBookingAvailable?: boolean
    price?: number
  }>(),
  {
    houseId: null,
    houseName: '',
    iconic: undefined,
    onlineBookingAvailable: undefined,
    price: undefined,
  },
)

const initalCurrentStep = () => {
  if (authenticated.value) {
    return wishlists.value?.length ? 'List' : 'AddFormList'
  }

  return 'Auth'
}

const currentStep = ref<'Auth' | 'List' | 'AddFormList'>(initalCurrentStep())

const currentStepAuth = ref('Email')
const selectedList = ref(null)
const loadingAddFavorite = ref(false)

const authenticatedAndFetched = computed(
  () => authenticated.value && fetched.value,
)

const modalSize = computed(() => {
  return authenticatedAndFetched.value || currentStepAuth.value === 'SignUp'
    ? 's'
    : 'xs'
})

const handleWishlists = async () => {
  await fetchWishlists()

  currentStep.value = wishlists.value?.length ? 'List' : 'AddFormList'
}

const addHouseFavorite = async () => {
  loadingAddFavorite.value = true
  if (props.houseId && props.houseName && selectedList.value) {
    try {
      const wishlistUpdated = await addFavorite(
        selectedList.value,
        props.houseId,
      )

      if (wishlistUpdated) {
        setLastWishlistTokenActive(wishlistUpdated.privateToken)

        const isTreatment = useExperimentCondition('multi-inquiry-from-search')
          .value.isTreatment

        const link = isMobile.value
          ? {
              title: t('wishlistMultiple.seeTheList'),
              to: !isTreatment
                ? localePath({
                    name: 'wishlist-token',
                    params: { token: wishlistUpdated.privateToken },
                  })
                : undefined,
              fn: isTreatment
                ? () => {
                    setSummaryOpen(true)
                  }
                : undefined,
            }
          : null

        const listName =
          wishlistUpdated.legacy && wishlistUpdated.title === 'My wishlist'
            ? t('wishlistMultiple.allMyFavourite')
            : wishlistUpdated.title

        createNotification({
          link,
          message: t('wishlistMultiple.addedTo', {
            houseName: props.houseName,
            listName,
          }),
          showCloseButton: false,
          type: 'secondary',
        })

        trackEvent({
          event: 'wishlist_house_added',
          category: 'wishlist',
          wishlist_id: selectedList.value,
          product: {
            house_id: props.houseId,
            house_name: props.houseName,
            iconic: props.iconic,
            online_booking_available: props.onlineBookingAvailable,
            price: props.price || undefined,
          },
        })
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  stateModal.value.multipleWishlistIsOpen = false
  loadingAddFavorite.value = false
}

watch(
  () => stateModal.value.multipleWishlistIsOpen,
  async (isOpen) => {
    if (!isOpen) {
      selectedList.value = null
      currentStep.value = initalCurrentStep()
    } else if (isOpen && authenticated.value) {
      await handleWishlists()
    }
  },
)

const houseIds = computed(() => (props.houseId ? [Number(props.houseId)] : []))
const link = computed(() => {
  if (currentStep.value === 'List') {
    return isMobile.value
      ? t('wishlistMultiple.newList')
      : t('wishlistMultiple.createNewList')
  }

  return ''
})
</script>

<template>
  <BaseModalV2
    v-model="stateModal.multipleWishlistIsOpen"
    :has-close-button="
      (!authenticated && currentStepAuth === 'Email') || authenticatedAndFetched
    "
    :link="link"
    :primary-button="currentStep === 'List' ? $t('global.validate') : ''"
    :primary-button-disabled="!Boolean(selectedList) || loadingAddFavorite"
    :size="modalSize"
    active-esc-mode
    @close="stateModal.multipleWishlistIsOpen = false"
    @link="currentStep = 'AddFormList'"
    @primary-button="addHouseFavorite"
  >
    <template #content>
      <AuthModalWrapper
        v-if="currentStep === 'Auth'"
        @current-step="currentStepAuth = $event"
        @on-success-login="handleWishlists"
      >
        <template #header>
          <div class="text-center">
            <div class="mb-3">
              <BaseIcon
                name="heart"
                :size="3"
                class="mx-auto animate-[animateHeart_1s_ease-in-out_forwards]"
              />
            </div>
            <div class="mb-1 text-md font-bold">
              {{ $t('auth.loginOrRegister') }}
            </div>
            <div class="mb-8 text-md">
              {{ $t('wishlistMultiple.notConnectedText') }}
            </div>
          </div>
        </template>
      </AuthModalWrapper>
      <WishlistMultipleModalWrapperList
        v-else-if="currentStep === 'List'"
        v-model="selectedList"
        :house-name="houseName"
        :list="wishlists"
        :loaded="fetched"
        class="mb-4 tablet:mb-10 tablet:mt-3"
      />
      <WishlistMultipleModalWrapperForm
        v-else-if="currentStep === 'AddFormList'"
        :house-ids="houseIds"
        :house-name="houseName"
        :title="$t('wishlistMultiple.createNewList')"
        class="tablet:mt-3"
        @wishlist-created="stateModal.multipleWishlistIsOpen = false"
      >
        <template v-if="!wishlists.length" #banner>
          <WishlistMultipleBannerAdd class="mb-6" />
        </template>
      </WishlistMultipleModalWrapperForm>
    </template>
  </BaseModalV2>
</template>

<style>
@keyframes animateHeart {
  30% {
    transform: scale(1.16);
    fill: theme('colors.primary.600');
    color: theme('colors.primary.600');
  }
  100% {
    transform: scale(1);
    fill: theme('colors.primary.500');
    color: theme('colors.primary.500');
  }
}
</style>
